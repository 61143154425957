<script>
  import Flag from './Flag.svelte';
  import Topline from './Topline.svelte';
  import { getCountryCount } from './hubspot';

  export let country = {};
  let country_data = {};

  $: updateCountry(country);
  
  const updateCountry = (async (country) => {
    country_data = {};
    if ('iso2' in country) {
      // Fetch counts.
      const [bronze, silver, gold] = await Promise.all([
        getCountryCount(country.iso2, 'Bronze'),
        getCountryCount(country.iso2, 'Silver'),
        getCountryCount(country.iso2, 'Gold'),
      ]);
      country_data = {
        'counts': [
          bronze + " Bronze DHC",
          silver + " Silver DHC",
          gold + " Gold DHC",
        ],
      };
    }
    else {
      country_data = {};
    }
  });
</script>

<style>
  .country {
    padding: 0 1rem;
    flex-basis: 300px;
    max-height: 100vh;
    overflow: scroll;
  }
</style>

{#if 'name' in country}
<div class="country">
  <h2>{country.name}</h2>
  <Flag country={country.iso3}/>

  {#if 'counts' in country_data}
    <Topline items={country_data.counts} heading="DHCE" />
  {/if}
</div>
{/if}