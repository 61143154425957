<script>
  import { getCenter } from './hubspot';

  export let centerId;
  let centerDetails;

  $: updateCenterDetails(centerId);

  const updateCenterDetails = async (centerId) => {
    if (centerId) {
      centerDetails = await getCenter(centerId);
      console.log(centerDetails)
    }
  }
</script>

<style>
  .center-details {
    padding: 0 1rem;
    flex-basis: 300px;
    max-height: 100vh;
    overflow: scroll;
  }
</style>

{#if centerDetails }
<div class="center-details">
  <h2>{centerDetails.name}</h2>

  {#if centerDetails.logo}
    <img src="{centerDetails.logo}" alt="Logo for {centerDetails.name}" />
  {/if}

  <p>Number of DHC: {centerDetails.num_associated_contacts}</p>

  {#if centerDetails.email || centerDetails.website}
  <h3>Contact Information</h3>
  {#if centerDetails.email}
  <p><a href="mailto:{centerDetails.email}">{centerDetails.email}</a></p>
  {/if}
  {#if centerDetails.website}
  <p><a href="{centerDetails.website}">{centerDetails.website}</a></p>
  {/if}
  {/if}
</div>
{/if}