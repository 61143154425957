<script>
  import { onMount, createEventDispatcher } from "svelte";

  export let projects = [];
  let markers = [];
  let datamap;
  let icons = {};

  const dispatch = createEventDispatcher();

  $: updateMarkers(projects);

  const updateMarkers = (projects) => {
    if (projects.length) {
      // Remove existing.
      markers.forEach((marker) => {
        marker.remove();
      });
      // Add projects.
      mapMarkers(projects);
      const markerGroup = new L.featureGroup(markers);
      datamap.fitBounds(markerGroup.getBounds());
    }
  }

  const mapMarkers = (data) => {
    // Generate markers
    markers = data.map((i) => {
      const icon = icons.rce;
      const marker = L.marker([i.latitude___car, i.longitude___car], {icon: icon}).addTo(datamap).on('click', (e) => {
        dispatch('marker_click', {
          id: i.hs_object_id,
        });
      });

      return marker;
    });
  };

  onMount(async () => {
    datamap = L.map('map-container')

    L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      maxZoom: 18,
      id: 'mapbox/streets-v11',
      tileSize: 512,
      zoomOffset: -1,
      accessToken: 'pk.eyJ1IjoiYWhlYnJhbmstZ2FyYyIsImEiOiJjbGxpZWkweHgxZzAwM2VxbG9ldnd1djZmIn0.FvalvSNZCcsHsE67bXSdAQ'
    }).addTo(datamap);

    icons = {
      rce: new L.Icon({
        iconUrl: 'markers/rce.png',
        shadowUrl: 'markers/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
      }),
    };

    // Country click borders.
    const res = await fetch('borders.json');
    const borders = await res.json();
    const border_geojson = L.geoJson(borders, {
      onEachFeature: (features, layer) => {
        layer.on({
          click: (e) => {
            dispatch('country_zoom', {
              iso2: layer.feature.properties.ISO2,
              iso3: layer.feature.properties.ISO3,
              name: layer.feature.properties.NAME
            });
            datamap.invalidateSize();
            datamap.fitBounds(layer.getBounds());
          },
          mouseover: (e) => {
            e.target.setStyle({
              opacity: 1,
            })
          },
          mouseout: (e) => {
            border_geojson.resetStyle(e.target);
          }
        });
      },
      style: {
        opacity: 0,
        fillOpacity: 0,
        // weight: 1,
      },
    }).addTo(datamap);
    
    // Reset button.
    const reset = new L.Control({
      position: 'topright',
    });

    reset.onAdd = (map) => {
      let azoom = L.DomUtil.create('button','resetzoom');
      azoom.innerHTML = "Reset Zoom";
      L.DomEvent
        .disableClickPropagation(azoom)
        .addListener(azoom, 'click', function() {
          dispatch('country_zoom', {});
          window.setTimeout(() => {
            map.invalidateSize();
            const markerGroup = new L.featureGroup(markers);
            map.fitBounds(markerGroup.getBounds());
          }, 200);
        }, azoom);
      return azoom;
    };
    reset.addTo(datamap);
  });
</script>

<style>
  .map-container {
    flex: 1 1 100%;
    height: 100vh;
  }
</style>

<div class="map-container" id="map-container"></div>