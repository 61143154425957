<script>
  export let country = '';
</script>

<style>
  img {
    width: 200px;
  }
</style>

{#if country}
  <img src="flags/{country.toLowerCase()}.svg" alt="Flag for {country}"/>
{/if}