const proxyUrl = process.env.IS_DEVELOPMENT ? 'http://localhost:8080/hubspot-proxy.php' : 'https://hsproxy.endrabiesnow.org/hubspot-proxy.php';

export const getCenters = async (type = '') => {
  if (type) {
    type = '&type=' + type
  }
  const res = await fetch(proxyUrl + '?resource=companies' + type);
  return await res.json();
};

export const getCountryCount = async (country, level) => {
  const res = await fetch(proxyUrl + `?resource=contact_count&country=${country}&level=${level}`);
  const json = await res.json();
  return json.count;
}

export const getCenter = async (id) => {
  const res = await fetch(proxyUrl + `?resource=company&id=${id}`);
  console.log(res)
  return await res.json();
}