<script>
  import { onMount } from 'svelte';
  import { getCenters } from './hubspot.js';

  // import Filters from './Filters.svelte';
  import Map from './Map.svelte';
  import CountryDetails from './CountryDetails.svelte';
  import CenterDetail from './CenterDetail.svelte';

  let projects;
  let filteredProjects;
  let countries = {};

  let selectedCountry;
  let selectedCenter;

  onMount(async () => {
    projects = await getCenters();
    projects.forEach((item) => {
      if (!(item.country in countries)) {
        countries[item.country] = item.country;
      }
    });
    filteredProjects = projects;
  });

  const countryZoom = (e) => {
    selectedCountry = e.detail;
    if ('code' in selectedCountry) {
      filteredProjects = projects.map((item) => {
        item = {...item, highlight: (item.country_code == selectedCountry.code)};
        return item;
      });
    }
    else {
      filteredProjects = projects;
    }
  };

  const markerClick = (e) => {
    console.log(e);
    selectedCenter = e.detail.id;
  }
</script>

<style global>
  body {
    margin: 0;
    padding: 0;
    background-color: #fff9ee;
    font-family: 'Montserrat', sans-serif;
    color: #808080;
  }
  h1, h2, h3, h4 {
    font-family: 'Merriweather', serif;
    color: #67615b;
  }
  .wrapper {
    display: flex;
  }
  a {
    color: #47c1bd;
  }
</style>

<main>
  <div class="wrapper">
    <CountryDetails country={selectedCountry}/>
    
    <Map projects={filteredProjects} on:country_zoom={countryZoom} on:marker_click={markerClick}/>

    <CenterDetail centerId={selectedCenter}/>
  </div>
</main>
