<script>
  export let items = {};
  export let heading = '';
</script>

<style>
  .counts {
    margin-top: 2rem;
    background-color: #fff;
    color: #47c1bd;
    padding: 1.5rem 1rem;
    text-align: center;
    border-top: 6px solid #47c1bd;
  }
  ul {
    padding: 0;
    list-style-type: none;
    margin-left: 0;
  }
  h3 {
    color:  #47c1bd;
    font-family: 'Montserrat', sans-serif;
    margin-top: 0;
  }
</style>

{#if items}
  <div class="counts">
    <h3>{heading}</h3>
    <ul>
      {#each items as item}
      <li>{item}</li>
      {/each}
    </ul>
  </div>
{/if}